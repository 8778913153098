import React from "react";
import "./updates.css"
import ComingSoon from "./coming";


export default function Updatespage() {
    return (
        <>
            <div className="headerblock"></div>
            <ComingSoon />

        </>
    )
}