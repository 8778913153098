import React from "react";
import "./coming.css"

export default function ComingSoon(){
    return(
        <>
        <div className="coming-soon-container">
            <h1>Coming Soon</h1>
        </div>
        </>
    )
}